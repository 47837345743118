import $ from 'jquery';
/* global ajax_object */
$(document).ready(function () {
  if (typeof ajax_object === 'undefined' || !ajax_object.ajax_url) {
    console.error(
      'ajax_object.ajax_url is not defined. Make sure the script is localized properly.'
    );
    return;
  }
  postsFilterWidget();
});

function postsFilterWidget() {
  $('.js-posts-filter-widget').each(function () {
    const $widget = $(this);
    const $container = $widget.find('#ajax-posts');
    const $pagination = $widget.find('.posts-pagination');
    const $filters = $widget.find('.filter-checkbox');
    const $filterHeader = $widget.find('.filter-header');
    const $filterContent = $widget.find('.filter-content');
    const $toggleIcon = $widget.find('.toggle-icon');
    const postsPerPage = $widget.data('posts-per-page');

    $filterHeader.on('click', function () {
      $filterContent.toggleClass('active');
      $toggleIcon.toggleClass('active');
    });

    function bindPaginationEvents() {
      $pagination.off('click', 'a');
      $pagination.on('click', 'a', function (e) {
        e.preventDefault();
        const href = $(this).attr('href');
        const pageMatch = href.match(/page\/(\d+)/);
        const page = pageMatch ? pageMatch[1] : 1;
        loadPosts(page);
      });
    }

    $filters.on('change', function () {
      loadPosts(1);
    });

    bindPaginationEvents();

    function loadPosts(page) {
      let filters = {};
      $filters.each(function () {
        if ($(this).is(':checked')) {
          const taxonomy = $(this).data('taxonomy');
          if (!filters[taxonomy]) {
            filters[taxonomy] = [];
          }
          filters[taxonomy].push($(this).val());
        }
      });

      const requestData = {
        action: 'resources_filter_ajax',
        paged: page,
        posts_per_page: postsPerPage,
        filters: filters,
      };

      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: ajax_object.ajax_url,
        data: requestData,
        success: function (response) {
          $container.html(response.html);
          $pagination.html(response.pagination);
          bindPaginationEvents();

          let needsReload = false;

          $filters.each(function () {
            const $checkbox = $(this);
            const taxonomy = $checkbox.data('taxonomy');
            const value = $checkbox.val();
            const isAvailable =
              response.available_filters[taxonomy].includes(value);
            const isDeactivated =
              response.deactivated_filters[taxonomy] &&
              response.deactivated_filters[taxonomy].includes(value);

            if (isDeactivated) {
              $checkbox.prop('checked', false);
              needsReload = true;
            }
            $checkbox.prop('disabled', !isAvailable);
            $checkbox.parent().css('opacity', isAvailable ? 1 : 0.5);
          });

          if (needsReload) {
            filters = {};
            $filters.each(function () {
              if ($(this).is(':checked')) {
                const taxonomy = $(this).data('taxonomy');
                if (!filters[taxonomy]) {
                  filters[taxonomy] = [];
                }
                filters[taxonomy].push($(this).val());
              }
            });

            requestData.filters = filters;

            $.ajax({
              type: 'POST',
              dataType: 'json',
              url: ajax_object.ajax_url,
              data: requestData,
              success: function (response) {
                $container.html(response.html);
                $pagination.html(response.pagination);
                bindPaginationEvents();

                $filters.each(function () {
                  const $checkbox = $(this);
                  const taxonomy = $checkbox.data('taxonomy');
                  const value = $checkbox.val();
                  const isAvailable =
                    response.available_filters[taxonomy].includes(value);
                  $checkbox.prop('disabled', !isAvailable);
                  $checkbox.parent().css('opacity', isAvailable ? 1 : 0.5);
                });
              },
              error: function (result) {
                console.warn('Error after reload:', result);
              },
            });
          }
        },
        error: function (result) {
          console.warn('Error:', result);
        },
      });
    }
  });
}
